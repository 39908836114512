import React from 'react'
import {Row,Col} from "react-bootstrap"

function PageNotFound() {
    return (
        <div className="error-page">
            <div className="cover">
            <Row className="justify-content-center align-items-center h-100 w-100 px-2 py-5 mx-0">
           <Col xs={12} className="text-center py-5  my-5 text-white font-link shadow">
                <h1 className="error-heading mb-4">404</h1>
                <h2 className="mb-5">Oops... Page Not Found</h2>
                <h4 className="my-4">The page you are looking for does not exist..</h4>
           </Col>
       </Row>
            </div>

        </div>
       
    )
}

export default PageNotFound
