
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Viewpage from './Pages/Viewpage';
import PageNotFound from './Pages/PageNotFound';


function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path='/:id' exact element={<Viewpage />}></Route>
          <Route path='/' element={<PageNotFound/>}></Route>
        </Routes>
      </Router>
    </>
  );
}


export default App;
