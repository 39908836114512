import React from 'react'
import { Row, Col, Image, Container } from 'react-bootstrap'
import { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios'
import { useParams } from 'react-router';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { getHeight, getWidth } from '../utils/dim'
import plywood from '../Images/plywood.webp'
import hands from '../Images/welcomeImage1.png'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer, toast } from 'react-toastify'

const HEIGHT = getHeight()

const notifySuccess = (toastText) => toast.success(toastText)
const notifyDanger = (toastText) => toast.error(toastText)

function Carousel_image2() {
    const [viewImage, setViewImage] = useState([])
    const [logoData, setLogoData] = useState({})
    const [selectedProduct,setSelectedProduct] = useState([])
    const [itemIdStatus,setItemIdStatus] = useState(false)
    const [selectedIcon,setSelectedIcon] = useState([])
    const params = useParams();
    const id = params.id
    const API_BASE_URL = "https://hp.plynveneer.com/"
    // const NEW_API_BASE_URL = "http://192.168.1.2/hp-erp/"
    // const IMG_BASE_URL = "https://hp.plynveneer.com/"

    const headers = { 'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
 }

    const [selected, setSelected] = useState(false)

    useEffect(() => {
        fetchData()
    },[id,itemIdStatus])




    const fetchData = () => {
        axios.get(`${API_BASE_URL}get-compared-image-pdf/${id}`)
            .then((response) => {
                if (response.data.status) {
                    setViewImage(response.data)
                }else{
                    alert(response.data.message)
                }
            })
            .catch((error) => {
                console.log("error")
            })

        axios.get(`${API_BASE_URL}show-webNlogo-onURl`)
            .then((response) => {
                setLogoData(response.data)
            })
            .catch((error) => {
                notifyDanger(error.message)
            })
    }

    const addProduct = (itemId) => {
        // setSelected(!selected)
        axios.post(`${API_BASE_URL}favorite-item-selected-onUrl`, {
            url_id: id,
            item_id: itemId
        })
            .then((response) => {
                setItemIdStatus(!itemIdStatus)
                console.log(itemIdStatus,"itemId")
                notifySuccess(response.data.msg)
            })
            .catch(err => {
                console.log(err)
            })
    }
   
    return (


        <>
            <div style={{ height: HEIGHT, width: "100%", backgroundImage: `url(${plywood})`, backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
                <Container className="pt-2 pb-0 h-100" autoFocus>
                    <Row className=" justify-content-center align-items-center h-100 mx-auto my-md-5 py-5  ">
                        <Col xs={12} md={12} className=" text-center font-link">
                            <h1 className="text-white py-5">
                                {Object.keys(viewImage).includes("party_name") ? viewImage.party_name : ""}
                            </h1>
                            <h1 className=" welcome">
                                <span className="font-link-4">Welcome To </span>
                                <span className="font-link-4 fs-1" >TEAQUE VENEER</span>
                            </h1>
                        </Col>
                        <Col xs={12} md={8} lg={6} className=" text-center">
                            <img src={hands} className="w-100" />
                        </Col>
                    </Row>
                </Container>

            </div>

            <div className="container-fluid bg-gray">
                <div className="container  py-2 ">
                    {viewImage.result && viewImage.result.rows.map((ele, ind) => {
                        return <Row className=" img-container box-shadow  my-5 bg-light pt-4 pb-2 px-1 px-lg-5" key={ind}>
                            <Row className="align-items-center justify-content-between">
                                <Col xs={9} md={8} className="font-link-3 mb-1 mb-md-2  ">
                                    <h1 className=" text-end product-name text-uppercase mb-0">{ele.item_name} ({ele.item_code})</h1>

                                </Col>
                                <Col xs={1} md={3} className=" text-end">
                                    <button type="button" className='wishlisht-btn' data-bs-toggle="tooltip" data-bs-placement="top" title="Select the product" onClick={() => addProduct(ele.item_id)}>
                                        <span className='text-end '>
                                        {ele.status=="TRUE" ? <i className="bi bi-hand-thumbs-up-fill text-primary fs-3"></i> :<i className="bi bi-hand-thumbs-up fs-3 "></i>}
                                        </span>
                                    </button>
                                </Col>
                            </Row>

                            {ele.water_natural_white == "" || ele.water_natural_white == null ? null :
                                <Col xs={12} md={6} className=" my-1" >
                                    <LazyLoadImage className=" border-radius  py-1"
                                        src={ele.water_natural_white}
                                        min-height="300px"
                                        width="100%"
                                        effect="blur"
                                    />
                                </Col>
                            }
                            {ele.water_warm_white == "" || ele.water_warm_white == null ? null :
                                <Col xs={12} md={6} className=" my-1" >
                                    <LazyLoadImage className=" border-radius  py-1"
                                        src={ele.water_warm_white}
                                        min-height="300px"
                                        effect="blur"
                                        width="100%"
                                    />
                                </Col>
                            }
                            {ele.without_water_natural_white == "" || ele.without_water_natural_white == null ? null :
                                <Col xs={12} md={6} className=" my-1" >
                                    <LazyLoadImage className=" border-radius  py-1"
                                        src={ele.without_water_natural_white}
                                        min-height="300px"
                                        effect="blur"
                                        width="100%"
                                    />
                                </Col>
                            }
                            {ele.without_water_warm_white == "" || ele.without_water_warm_white == null ? null :
                                <Col xs={12} md={6} className=" my-1" >
                                    <LazyLoadImage className=" border-radius  py-1"
                                        src={ele.without_water_warm_white}
                                        min-height="300px"
                                        effect="blur"
                                        width="100%"
                                    />
                                </Col>
                            }
                            <Col xs={12} className="text-end">

                                <a href={logoData.web_url} target="_blank" className="website-url font-link">
                                    <img src={logoData.image_logo} className="logo mx-3" />
                                    <p className='mb-0'>{logoData.url_text}</p>
                                </a>
                            </Col>
                        </Row>
                    })}

                </div>
            </div>

            <div
                style={{ height: "99.5vh", width: "100%", marginTop: "0px", backgroundImage: `url(${plywood})`, backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
                <Row className="  py-5 w-100 justify-content-center align-items-center h-100 m-auto px-2">
                    <Col xs={10} className="py-5 my-5  " >
                        <h1 className="py-5 welcome text-center">
                            <span className="font-link-2">Thanks For Visiting</span>

                        </h1>
                    </Col>
                </Row>
            </div>
            <ToastContainer />
        </>
    )
}

export default Carousel_image2


